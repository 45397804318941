@use '@/scss/underscore' as _;


@mixin link-split {
  &::after {
    content: '';
    width: 1px;
    height: 12px;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    background-color: var(--color-brand-default);
    opacity: 60%;
  }
}

.textLink {
  text-align: center;

  .signInMethodLink {
    font-size: inherit;
  }
}

.methodsLinkList {
  @include _.flex-row;
  justify-content: center;

  .signInMethodLink {
    padding: 0 _.unit(4);
    position: relative;

    @include link-split;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;

      &::after {
        content: none;
      }
    }
  }
}

:global(body.desktop) {
  .methodsLinkList {
    .signInMethodLink {
      padding: 0 _.unit(5);
      position: relative;

      @include link-split;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;

        &::after {
          content: none;
        }
      }
    }
  }
}
