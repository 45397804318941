@use '@/scss/underscore' as _;

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--color-bg-mask);
  @include _.flex-column;
  z-index: 300;
}

.loadingIcon {
  color: var(--color-type-primary);
  animation: rotating 1s steps(12, end) infinite;
}

.container {
  width: 60px;
  height: 60px;
  border-radius: var(--radius);
  background-color: var(--color-bg-toast);
  @include _.flex-column;

  .loadingIcon {
    color: var(--color-static-dark-type-primary);
  }
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
