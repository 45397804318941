@use '@/scss/underscore' as _;


.container {
  @include _.flex-column;

  > * {
    width: 100%;
  }
}

.desc {
  @include _.text-hint;
  text-align: left;

  &:not(:first-child) {
    margin-top: _.unit(8);
  }
}


:global(body.mobile) {
  .desc {
    margin-bottom: _.unit(2);
  }
}

:global(body.desktop) {
  .desc {
    margin-bottom: _.unit(4);
  }
}
