@use '@/scss/underscore' as _;

.form {
  @include _.flex-column;

  > * {
    width: 100%;
  }

  .inputField {
    margin-bottom: _.unit(8);

    &.withError {
      margin-bottom: _.unit(2);
    }
  }

  .message {
    font: var(--font-body-2);

    > span {
      color: var(--color-brand-default);
    }
  }

  .message,
  .link,
  .switch {
    width: auto;
    align-self: start;
  }

  .switch {
    margin-top: _.unit(3);
  }
}

:global(body.mobile) {
  .message {
    color: var(--color-type-primary);
  }
}

:global(body.desktop) {
  .message {
    color: var(--color-type-secondary);
  }
}
