@use '@/scss/underscore' as _;

.overlay {
  z-index: 100;
}

.modal {
  position: absolute;
  left: 20px;
  right: 20px;
  top: 50%;
  transform: translate(0, -50%);
  outline: none;
  border-radius: var(--radius);

  &:focus-visible {
    outline: none;
  }
}

.container {
  padding: _.unit(5);
  background: var(--color-bg-body-overlay);
  border-radius: var(--radius);

  &:focus-visible {
    outline: none;
  }
}

.content {
  text-align: center;
  font: var(--font-body-1);
  color: var(--color-type-primary);
}

.footer {
  @include _.flex_row;
  margin-top: _.unit(6);

  > * {
    flex: 1;
  }

  > button:first-child {
    margin-right: _.unit(2);
  }
}

.iframeModal {
  top: 40px;
  bottom: 40px;
  transform: none;

  .container {
    padding: 0;
    border-radius: 12px;
    width: 100%;
    height: 100%;
    @include _.flex_column(stretch, center);
  }

  .content {
    padding: _.unit(5);
    flex: 1;
    @include _.flex_column;
  }

  .footer {
    margin-top: 0;
    border-top: 1px solid var(--color-line-divider);
    padding: _.unit(5);
  }
}
