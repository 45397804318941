@use '@/scss/underscore' as _;

.terms {
  @include _.flex-row;
  width: 100%;
  user-select: none;
  cursor: pointer;
}

.checkBox {
  margin-right: _.unit(2);
  fill: var(--color-type-secondary);
  cursor: pointer;
}

.content {
  @include _.text-hint;

  .link {
    @include _.text-hint;
  }
}

.errorMessage {
  margin-top: _.unit(2);
}


:global(body.mobile) {
  .terms {
    padding: _.unit(1) 0;
  }
}
