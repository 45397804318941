@use '@/scss/underscore' as _;

.wrapper {
  position: relative;
  @include _.flex-row;
  border: _.border(var(--color-line-border));
  border-radius: var(--radius);
  // fix in safari input field line-height issue
  height: 44px;
  overflow: hidden;
  transition-property: outline, border;
  transition-timing-function: ease-in-out;
  transition-duration: 0.2s;

  .actionButton {
    position: absolute;
    right: _.unit(4);
    top: 50%;
    transform: translateY(-50%);
    display: none;
    color: var(--color-type-secondary);
    width: 24px;
    height: 24px;
  }

  input {
    padding: 0 _.unit(4);
    flex: 1;
    background: none;
    caret-color: var(--color-brand-default);
    font: var(--font-body-1);
    color: var(--color-type-primary);
    align-self: stretch;

    &::placeholder {
      color: var(--color-type-secondary);
    }
  }

  &:focus-within {
    border: _.border(var(--color-brand-default));

    .actionButton {
      display: block;
    }

    input {
      padding-right: calc(24px + _.unit(4));
      outline: none;
    }
  }

  &.error {
    border: _.border(var(--color-danger-default));
  }
}

.errorMessage {
  margin-left: _.unit(0.5);
  margin-top: _.unit(1);
}

:global(body.desktop) {
  .wrapper {
    border-radius: 6px;
    outline: 3px solid transparent;

    input {
      font: var(--font-body-2);
    }

    .actionButton {
      width: 22px;
      height: 22px;
    }

    &.error {
      border: _.border(var(--color-danger-default));
    }

    &:focus-within {
      border: _.border(var(--color-brand-default));
      outline-color: var(--color-overlay-brand-focused);
    }
  }
}
