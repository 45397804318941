@use '@/scss/underscore' as _;

.form {
  @include _.flex-column;

  > * {
    width: 100%;
  }

  .inputField,
  .terms,
  .switch,
  .formErrors {
    margin-bottom: _.unit(4);
  }

  .switch {
    width: auto;
    align-self: start;
  }

  .formErrors {
    margin-left: _.unit(0.5);
    margin-top: _.unit(-3);
  }
}
